<template>
  <svg ref="plantObject" class="plant-object">
    <circle
        v-if="!(simplify)"
        :cx="xPositionUpdated"
        :cy="yPositionUpdated"
        :fill="plantColor"
        :r="plantRadius"
        fill-opacity="0.3"
        stroke="var(--dark)"
        stroke-width="2"
        style="cursor: pointer;"/>

    <foreignObject
        v-if="!(simplify)"
        :height="plantRadius*2"
        :width="plantRadius*3"
        :x="xPositionUpdated-plantRadius*3/2"
        :y="yPositionUpdated-plantRadius">
      <body class="bg-transparent position-relative">
      <div class="d-flex justify-content-center align-items-center overflow-hidden">
        <img
            :alt="imageDescription"
            :src="getImage"
            :style="'max-width:' + plantRadius*1.0 + 'px; max-height:' + plantRadius*1.0  + 'px;'"
            class="plant-image"
            draggable="false">

        <PlantScore
            :received_interactions="plantInteractions"
            :vGarden="vGarden"
            :style="'max-width:' + plantRadius*0.8 + 'px; max-height:' + plantRadius*0.8  + 'px;'"
            class="plant-score"
            notEmpty/>

        <div v-if="quantity > 1" class="plant-quantity hidden"> x{{ quantity }}</div>

        <div :style="'max-width:' + plantRadius*2 + 'px;'" class="plant-name text-truncate">
          {{ plantName.split(' ')[0] }}
        </div>
      </div>
      </body>
    </foreignObject>

    <circle
        v-if="!(simplify)"
        :id="'circleObject' + plantIndex"
        :cx="xPositionUpdated"
        :cy="yPositionUpdated"
        :r="plantRadius"
        class="circle"
        fill="transparent"
        stroke="var(--dark)"
        stroke-width="0"
        style="cursor: pointer;"/>

    <!-- Text that replaces circles in interaction composent if there are too many plants for screen size -->
    <text
        v-if="simplify"
        :id="'circleObject' + plantIndex"
        :fill="textColor"
        :transform="'rotate(' + rotation + ' ' + xPositionUpdated + ',' + yPositionUpdated + ')'"
        :x="xPositionUpdated"
        :y="yPositionUpdated"
        class="circle"
        style="cursor: pointer;">
      {{ plantName }}
    </text>
  </svg>
</template>

<script type="text/javascript">
import PlantScore from "@/components/plants/PlantScore.vue"

export default {
  name: "VGardenPlantObject",
  components: {
    PlantScore
  },
  props: {
    vGarden: {
      type: Object,
      default: null
    },
    plantName: {
      type: String,
      default: 'plant name'
    },
    plantIndex: {
      type: Number,
      default: -1
    },
    quantity: {
      type: Number,
      default: -1
    },
    plantInteractions: {
      type: Array,
      default: null
    },
    xPosition: {
      type: Number,
      default: 1
    },
    yPosition: {
      type: Number,
      default: 1
    },
    plantRadius: {
      type: Number,
      default: 1
    },
    plantColor: {
      type: String,
      default: 'var(--primary-light)'
    },
    textColor: {
      type: String,
      default: 'black'
    },
    maxWidth: {
      type: Number,
      default: -1
    },
    maxHeight: {
      type: Number,
      default: -1
    },
    borderSize: {
      type: Number,
      default: -1
    },
    simplify: {
      type: Boolean,
      default: false
    },
    rotation: {
      type: Number,
      default: 0
    },
    pictoName: {
      type: String,
      default: 'default_leaf.svg'
    }
  },
  data() {
    return {
      xPositionUpdated: 0,
      yPositionUpdated: 0,
      initialMaxWidth: this.maxWidth,
      moved: false
    }
  },
  created() {
    this.xPositionUpdated = this.xPosition
    this.yPositionUpdated = this.yPosition
  },
  mounted() {
    if (document.getElementById('circleObject' + this.plantIndex)) {
      document.getElementById('circleObject' + this.plantIndex).addEventListener('click', this.select);
    }
    if (document.getElementById('textObject' + this.plantIndex)) {
      document.getElementById('textObject' + this.plantIndex).addEventListener('click', this.select);
    }
  },
  computed: {
    getImage() {
      let img = ""
      try {
        img = require("@/assets/images/icons/plants/" + this.pictoName)
      } catch (e) {
        return require("@/assets/images/icons/plants/default_leaf.svg")
      }
      return img
    },
    imageDescription() {
      return "Image de " + this.plantName
    }
  },
  methods: {
    select() {
      this.$emit('plantClick', {plantId: this.plantIndex})
    }
  },
  watch: {
    '$store.state.windowWidth'() {
      this.xPositionUpdated = this.xPositionUpdated * this.maxWidth / this.initialMaxWidth
      this.yPositionUpdated = this.yPositionUpdated * this.maxWidth / this.initialMaxWidth //because its the proportion that counts,
      this.initialMaxWidth = this.maxWidth
    }
  }
}
</script>

<style lang="scss">
svg {
  pointer-events: none !important;
}

.plant-object {
  .plant-image {
    position: absolute;
    bottom: 40%;
    right: 50%;
    z-index: 0;
  }

  .plant-score {
    position: absolute;
    bottom: 50%;
    left: 50%;
    height: 3rem;
  }

  .plant-quantity {
    position: absolute;
    bottom: 3%;
    left: 50%;
    font-style: italic;
    color: gray;
    transform: translateX(-50%);
  }

  .plant-name {
    position: absolute;
    top: 60%;
    color: black;
    font-weight: 500;
  }

  .circle {
    pointer-events: auto !important;
  }

}
</style>
